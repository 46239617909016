import React from 'react';
import ReactDOM from 'react-dom';
import 'materialize-css';
import 'materialize-css/dist/css/materialize.min.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import config from './configuracion';
const { getKeysCredentials } = config;

// import '@tdp/rt-desing-components/dist/assets/rt-desing-components.css';

// security
// eslint-disable-next-line no-unused-vars

getKeysCredentials().then((x) => {
  ReactDOM.render(<App />, document.getElementById('root'));
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
