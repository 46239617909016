import React, { Component } from 'react';
import './Login.scss';
import { connect } from 'react-redux';
import { CONSTANTS } from '../../utils/Constants';

import Alert from '../modals/Alert';
import Validations from '../../utils/Validations';
import Conditions from '../../components/modals/Conditions';
import iconMovistar from '../../resources/svg/login/iconLogo2.svg';
import iconMap from '../../resources/svg/login/iconMap.svg';
import iconError from '../../resources/svg/login/iconError.svg';
import iconArrow from '../../resources/svg/login/iconArrow.svg';
import Loading from '../../components/common/elements/Loading';
import Footer from '../../components/common/elements/Footer';

import iconArrowRight from '../../resources/svg/login/iconArrowRight.svg';
import backgroundWeb from '../../resources/svg/login/backgroundWeb.svg';

import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';

import * as faultActions from '../../store/actions/averia.actions';
import * as securityActions from '../../store/actions/security.actions';
import * as provisionActions from '../../store/actions/provision.actions';
import * as scheduleActions from '../../store/actions/schedule.actions';
import { Visibility } from '@material-ui/icons';
import { TdpSpinner } from '@tdp/rt-desing-components';
import HeaderDelivery from '../headerDelivery/HeaderDelivery';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      openAlert: false,
      openModal: false,
      validateNumberDoc: false,
      disabledContinue: true,
      isSchedule: false,
      valueDocType: 1, //DNI por defecto
      valueDocNumber: '',
      maxLengthDoc: 8, //DNI spor defecto
      errorMsj: '',
      errorMsjTitle: '',
      validateRuc: false,
      showError: false,
      patternText: '[0-9]',
      inputType: 'number',

      iconSelectComp: iconArrow,
    };
  }

  onChangeDocType = (event) => {
    window.dataLayer.push({
      event: 'atm.event',
      eventCategory: 'login',
      eventAction: 'click',
      eventLabel: 'tipo de documento',
      nonInteraction: '1',
      //datos genéricos
      userId: '',
      tipoCliente: '',
      numero: '',
      productos: '',
    });

    let regExp = /^[0-9]+$/;
    let inputType = 'number';

    switch (event.target.value) {
      case 2:
        regExp = /^[A-Za-z0-9]+$/;
        inputType = 'text';
        break;
      case 4:
        regExp = /^[aA-Z-z0-9]+$/;
        inputType = 'text';
        break;
      default:
        regExp = /^[0-9]+$/;
        inputType = 'number';
        break;
    }

    this.setState({
      validateNumberDoc: false,
      valueDocType: event.target.value,
      valueDocNumber: '',
      maxLengthDoc: this.setLengthInput(event.target.value),
      disabledContinue: true,
      showError: false,
      errorMsj: '',
      errorMsjTitle: '',
      patternText: regExp,
      inputType: inputType,
    });

    this.inputDocTypeFull();
  };

  onChangeDocNumber = (event) => {
    switch (this.state.valueDocType) {
      case 1:
        if (event.target.value.length > 8) {
          var valor = event.target.value;
          valor = valor.substring(0, valor.length - 1);
          this.setState({
            valueDocNumber: valor,
          });
          return false;
        }
        break;
      case 3: //RUC
        if (event.target.value.length > 11) {
          var valor = event.target.value;
          valor = valor.substring(0, valor.length - 1);
          this.setState({
            valueDocNumber: valor,
          });
          return false;
        }
        break;
      case 2:
      case 4:
        var regex = new RegExp('^[a-zA-Z0-9]*$');
        if (!regex.test(event.target.value)) {
          var valor = event.target.value;
          valor = valor.substring(0, valor.length - 1);
          this.setState({
            valueDocNumber: valor,
          });
          event.preventDefault();
          return false;
        }

        if (event.target.value.length > 12) {
          var valor = event.target.value;
          valor = valor.substring(0, valor.length - 1);
          this.setState({
            valueDocNumber: valor,
          });
          return false;
        }
        break;
      case 5:
        if (event.target.value.length > 9) {
          var valor = event.target.value;
          valor = valor.substring(0, valor.length - 1);
          this.setState({
            valueDocNumber: valor,
          });
          return false;
        }
        break;
    }

    let validaRuc = !Validations.validarRUC(
      event.target.value,
      this.state.valueDocType
    );

    this.setState({
      validateRuc: validaRuc,
    });

    this.setState({
      valueDocNumber: event.target.value,
      validateNumberDoc: !Validations.validarDocumento(
        event.target.value,
        this.state.valueDocType
      ),
      disabledContinue: !Validations.validateContinue(
        event.target.value,
        this.state.valueDocType
      ),
      validateRuc: !Validations.validarRUC(
        event.target.value,
        this.state.valueDocType
      ),
    });

    if (validaRuc == true) {
      this.setState({
        showError: true,
        errorMsj: 'El número RUC debe empezar con 10',
      });
    } else {
      this.setState({
        showError: false,
        errorMsj: '',
      });
    }

    if ((this.state.valueDocNumber = '')) {
      this.setState({
        validateNumberDoc: false,
        validateRuc: false,
        showError: false,
        errorMsj: '',
      });
    }
  };

  onGTM = () => {
    window.dataLayer.push({
      event: 'atm.event',
      eventCategory: 'login',
      eventAction: 'click',
      eventLabel: 'www.movistar.com.pe',
      nonInteraction: '1',
      //datos genéricos
      userId: '',
      tipoCliente: '',
      numero: '',
      productos: '',
    });
  };

  onContinue = () => {
    let tipoDoc = '';

    switch (this.state.valueDocType) {
      case 1:
        tipoDoc = 'DNI';
        break;
      case 2:
        tipoDoc = 'CEX';
        break;
      case 3:
        tipoDoc = 'RUC';
        break;
      case 4:
        tipoDoc = 'P';
        break;
      case 5:
        tipoDoc = 'PTP';
        break;
      default:
        break;
    }

    let request = {
      documentType: tipoDoc,
      documentNumber: this.state.valueDocNumber,
      source: 'WEB',
      // traceType: "" //provicion, averia, o delivery
    };

    /*
        / Servicio que trae la lista de Provisiones y Avarías
        */
    this.setState({ showLoading: true });
    this.props.securityLoginTokenAcess(request).then((resp) => {
      // responde ok cuando tiene una lista de provisiones o averias
      console.log('resp: ', resp);
      if (resp.status === 'OK') {
        this.setState({ showLoading: false });
        this.props.history.push({
          pathname: CONSTANTS.PATH.LIST,
        });
      } else if (resp.status === 'TOKEN') {
        /*
                / Servicio que envía el Token
                */
        let body = {
          traceId: resp.order.traceId,
          orderCode: resp.order.orderCode,
          jwt: this.props.jwtAuthUser,
        };
        console.log('body', body);
        if (
          resp.order.activityType.toLowerCase() ==
          CONSTANTS.PRODUCT.PROVISION_TYPE
        ) {
          this.props.sendProvisionToken(body).then((respToken) => {
            this.setState({ showLoading: false });
            console.log('respToken if: ', respToken);
            if (respToken.status === 'OK') {
              this.props.history.push({
                pathname: CONSTANTS.PATH.TOKEN,
              });
            } else if (respToken.status === 'NO_DATA') {
              this.setState({
                showError: true,
                errorMsj: respToken.message,
              });
            } else {
              this.setState({
                openAlert: true,
                errorMsj: respToken.message,
              });
            }
          });
        } else {
          this.props.sendFaultToken(body).then((respToken) => {
            console.log('respToken else: ', respToken);
            this.setState({ showLoading: false });

            if (respToken.status === 'OK') {
              this.props.history.push({
                pathname: CONSTANTS.PATH.TOKEN,
              });
            } else if (respToken.status === 'NO_DATA') {
              this.setState({
                showError: true,
                errorMsj: respToken.message,
              });
            } else if (respToken.status === 'ERROR') {
              this.setState({
                openAlert: true,
                errorMsj: respToken.message,
              });
            }
          });
        }
      } else if (resp.status === 'NO_DATA') {
        this.setState({
          showLoading: false,
          showError: true,
          errorMsj: resp.message,
        });
      } else if (resp.status === 'ERROR') {
        this.setState({
          showLoading: false,
          openAlert: true,
          errorMsj: resp.message,
        });
      }
    });

    window.dataLayer.push({
      event: 'atm.event',
      eventCategory: 'login',
      eventAction: 'click',
      eventLabel: 'continuar',
      nonInteraction: '1',
      //datos genéricos
      userId: tipoDoc,
      tipoCliente: this.state.valueDocNumber.toLowerCase(),
      numero: '',
      productos: '',
    });
  };
  onContinue2 = () => {
    let tipoDoc = '';

    switch (this.state.valueDocType) {
      case 1:
        tipoDoc = 'DNI';
        break;
      case 2:
        tipoDoc = 'CEX';
        break;
      case 3:
        tipoDoc = 'RUC';
        break;
      case 4:
        tipoDoc = 'P';
        break;
      case 5:
        tipoDoc = 'PTP';
        break;
      default:
        break;
    }
    this.props.setDocType(tipoDoc);
    this.setState({ showLoading: true });
    let request = {
      documentType: tipoDoc,
      documentNumber: this.state.valueDocNumber,
      source: 'WEB',
    };
    this.props.setDocNumber(request.documentNumber);
    this.props.getApplicationParamTypes(request).then((resp) => {
      if (resp.status === 'OK') {
        this.props.setActiveOrderTypes(resp.data);
        console.log('resp.data', resp.data);
        this.props.history.push({
          pathname: CONSTANTS.PATH.TYPES,
        });
      } else {
        // CUANDO EL STATUS SEA *NO_PARAM*
        this.props.history.push({
          pathname: CONSTANTS.PATH.SIN_PEDIDO,
        });
      }
    });
  };

  onCloseAlert = () => {
    this.setState({
      openAlert: false,
    });
  };

  onConditions = () => {
    window.dataLayer.push({
      event: 'atm.event',
      eventCategory: 'login',
      eventAction: 'click',
      eventLabel: 'términos y condiciones',
      nonInteraction: '0',
      //datos genéricos
      userId: '',
      tipoCliente: '',
      numero: '',
      productos: '',
    });

    this.setState(
      {
        openModal: true,
      },
      () => {
        if (this.state.openModal) {
          window.dataLayer.push({
            event: 'atm.event',
            eventCategory: 'login',
            eventAction: 'view',
            eventLabel: 'términos y condiciones',
            nonInteraction: '1',
            //datos genéricos
            userId: '',
            tipoCliente: '',
            numero: '',
            productos: '',
          });
        }
      }
    );
  };

  onAccept = () => {
    window.dataLayer.push({
      event: 'atm.event',
      eventCategory: 'login',
      eventAction: 'click',
      eventLabel: 'aceptar t&c',
      nonInteraction: '1',
      //datos genéricos
      userId: '',
      tipoCliente: '',
      numero: '',
      productos: '',
    });

    this.setState({
      openModal: false,
    });
  };

  onClose = () => {
    window.dataLayer.push({
      event: 'atm.event',
      eventCategory: 'login',
      eventAction: 'click',
      eventLabel: 'cerrar modal',
      nonInteraction: '1',
      //datos genéricos
      userId: '',
      tipoCliente: '',
      numero: '',
      productos: '',
    });
    this.setState({
      openModal: false,
    });
  };

  setLengthInput = (tipodocumento) => {
    switch (tipodocumento) {
      case 1:
        return 8; //DNI
      case 2:
        return 12; //CE
      case 3:
        return 11; //RUC
      case 4:
        return 12; //PASAPORTE
      case 5:
        return 9; //PTP
      default:
        return 8;
    }
  };

  onPaste = (e) => {
    e.preventDefault();
  };

  onlyNumbers = (e) => {
    const value = e.target.value;

    switch (this.state.valueDocType) {
      case 2:
      case 4:
        var regex = new RegExp('^[a-zA-Z0-9]*$');
        //let validCharacters = /^[a-zA-Z0-9\*]+$/g;
        var key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        //let cons = Validations.validarAlfanumerico(key);
        //console.log("cons>>>>",cons);
        if (!regex.test(key)) {
          //if(/[^a-zA-Z0-9]/.test(value)){
          //  if(validCharacters.test(value) === false){
          e.preventDefault();
          return false;
        }
        break;

      default:
        var key = window.event ? e.which : e.keyCode;
        if (key < 48 || key > 57) {
          e.preventDefault();
          return false;
        }
        break;
    }
  };

  validateDocNumber = () => {
    window.dataLayer.push({
      event: 'atm.event',
      eventCategory: 'login',
      eventAction: 'click',
      eventLabel: 'número de documento',
      nonInteraction: '1',
      //datos genéricos
      userId: '',
      tipoCliente: '',
      numero: '',
      productos: '',
    });

    if (this.state.valueDocNumber != '') {
      if (!this.state.showError) {
        this.setState({
          showError: this.state.disabledContinue,
          errorMsj: 'El número de documento ingresado no es válido',
        });
      }
    }
  };

  componentDidMount = () => {
    window.dataLayer.push({
      event: 'atm.event',
      eventCategory: 'page view',
      eventAction: 'view',
      eventLabel: 'login',
      path: this.props.history.location.pathname,
      nonInteraction: '1',
      //datos genéricos
      userId: '',
      tipoCliente: '',
      numero: '',
      productos: '',
    });

    this.props.securityReset();
    this.props.provisionReset();
    this.props.scheduleReset();

    this.props
      .getApplicationParam(CONSTANTS.PARAM.PARAM_MAINTANCE)
      .then((resp) => {
        if (resp.status === 'OK') {
          this.props.history.push({
            pathname: CONSTANTS.PATH.MAINTENANCE,
          });
        }
      });

    //Login por Viewer
    let urlParams = new URLSearchParams(window.location.search);
    let webView = urlParams.get('webView');
    //http://localhost:3000/login?webView=1
    // console.log("numero::: " + Number("http://localhost:3000/login?webView=1"));
    // console.log("webView::: " + webView);
    if (Number(webView) === 1) {
      this.props.securityHideHeader(Number(webView));
    }
  };

  clickGTM = (e) => {
    e.preventDefault();

    window.dataLayer.push({
      event: 'atm.event',
      eventCategory: 'login',
      eventAction: 'click',
      eventLabel: 'número de documento',
      nonInteraction: '1',
      //datos genéricos
      userId: '',
      tipoCliente: '',
      numero: '',
      productos: '',
    });
  };

  inputDocTypeFull = () => {
    let labelType = document.querySelector(
      '#login .container .content-body .boxCredentials .Credentials-wrapper .Inputs-wrapper .formControlDocument .MuiInputLabel-outlined.MuiInputLabel-shrink'
    );
    labelType.style.color = '#50535A';
    let borderType = document.querySelector(
      '#login .container .content-body .boxCredentials .Credentials-wrapper .Inputs-wrapper .formControlDocument .MuiOutlinedInput-notchedOutline'
    );
    borderType.style.border = '1px solid #50535A';
  };

  inputDocNumberFull = () => {
    let labelNumber = document.querySelector(
      '#login .container .content-body .boxCredentials .Credentials-wrapper .Inputs-wrapper .DocNumber-wrapper .textFieldDocument .MuiInputLabel-outlined.MuiInputLabel-shrink'
    );
    labelNumber.style.color = '#50535A';

    let borderNumber = document.querySelector(
      '#login .container .content-body .boxCredentials .Credentials-wrapper .Inputs-wrapper .DocNumber-wrapper .textFieldDocument .MuiOutlinedInput-notchedOutline'
    );
    borderNumber.style.border = '1px solid #50535A';
  };

  inputDocNumberEmpty = () => {
    let labelNumber = document.querySelector(
      '#login .container .content-body .boxCredentials .Credentials-wrapper .Inputs-wrapper .DocNumber-wrapper .textFieldDocument .MuiInputLabel-outlined.MuiInputLabel-shrink'
    );
    if (labelNumber != null) {
      labelNumber.style.color = '#B6B7B7';
    }

    let borderNumber = document.querySelector(
      '#login .container .content-body .boxCredentials .Credentials-wrapper .Inputs-wrapper .DocNumber-wrapper .textFieldDocument .MuiOutlinedInput-notchedOutline'
    );
    if (borderNumber != null) {
      borderNumber.style.border = '1px solid #B6B7B7';
    }
  };

  render() {
    if (this.state.disabledContinue == false) {
      this.inputDocNumberFull();
    } else {
      this.inputDocNumberEmpty();
    }

    return (
      <section id="login">
        <div>
          <Loading id="loading-login" showLoading={this.state.showLoading} />
          <HeaderDelivery />
          <div className="container">
            <div className="content-body">
              <div className="boxGroup">
                <img src={iconMap} className="iconMap" alt="mapa" />
                <div className="Wrapper-title">
                  <p id="lbl-title-login" className="titleSingle">
                    Seguimiento de pedido
                  </p>
                  <p id="lbl-subtitle-login" className="descSingle">
                    Selecciona e ingresa tu número de documento de identidad
                    para darle
                    <br />
                    seguimiento a tu pedido.
                  </p>
                </div>
              </div>
              <div className="boxCredentials">
                <div className="Credentials-wrapper">
                  <div className="Inputs-wrapper">
                    <FormControl
                      variant="outlined"
                      className="formControlDocument"
                    >
                      <InputLabel>Tipo de documento</InputLabel>
                      <Select
                        labelId="lbl-doctype-login"
                        id="sel-doctype-login"
                        className="combo-select"
                        value={this.state.valueDocType}
                        onChange={this.onChangeDocType}
                        label="Tipo de documento"
                        displayEmpty
                        IconComponent={(props) => (
                          <img
                            {...props}
                            src={this.state.iconSelectComp}
                            style={{ padding: '6px 8px 6px 6px' }}
                          />
                        )}
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                          },
                        }}
                      >
                        <MenuItem value={1}>DNI</MenuItem>
                        <MenuItem value={2}>Carnet de extranjería</MenuItem>
                        <MenuItem value={3}>RUC</MenuItem>
                        <MenuItem value={4}>Pasaporte</MenuItem>
                        <MenuItem value={5}>PTP</MenuItem>
                      </Select>
                    </FormControl>

                    <div className="DocNumber-wrapper">
                      <TextField
                        id="outlined-input"
                        className="textFieldDocument"
                        variant="outlined"
                        label="Número de documento"
                        autoComplete="off"
                        error={this.state.showError}
                        onChange={this.onChangeDocNumber}
                        onBlur={this.validateDocNumber}
                        onPaste={this.onPaste}
                        onKeyPress={this.onlyNumbers}
                        inputProps={{
                          maxLength: this.state.maxLengthDoc,
                          value: this.state.valueDocNumber,
                          type: this.state.inputType,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              style={
                                this.state.showError
                                  ? { visibility: 'visible' }
                                  : { visibility: 'collapse' }
                              }
                              position="start"
                            >
                              <img src={iconError} />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <p id="lbl-error-login" className="labelMesssage">
                        {this.state.showError ? this.state.errorMsj : ''}
                      </p>
                    </div>
                  </div>

                  <div className="boxConditions">
                    <p id="lbl-conditions-login" className="condition">
                      Al continuar habrás leído y aceptado nuestros <br />
                      <a
                        id="lnk-conditions-login"
                        className="link login-link"
                        onClick={this.onConditions}
                      >
                        <u>Términos y condiciones</u>
                      </a>
                    </p>
                  </div>

                  <Button
                    id="btn-continue-login"
                    style={{
                      backgroundColor: this.state.disabledContinue
                        ? '#A9B3B9'
                        : '#019BEF',
                    }}
                    variant="contained"
                    color="primary"
                    disabled={this.state.disabledContinue}
                    onClick={this.onContinue2}
                    endIcon={<Avatar src={iconArrowRight} />}
                  >
                    Continuar
                  </Button>
                  <div className="boxConditions">
                    <p id="lbl-conditions-login" className="help">
                      Si necesitas ayuda
                      <br />
                      <a
                        id="lnk-conditions-login"
                        className="link login-link"
                        href="https://api.whatsapp.com/send?phone=51999955555&text=%C2%A1Hola!%20Me%20derivaron%20de%20la%20WEB%20de%20Trazabilidad%20para%20que%20me%20ayuden%20a%20resolver%20mis%20dudas"
                      >
                        <u>Escríbenos por WhatsApp</u>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Alert
            id="mdl-error-login"
            openAlert={this.state.openAlert}
            errorMsj={this.state.errorMsj}
            onClose={this.onCloseAlert}
            onAccept={this.onCloseAlert}
          />
          <Conditions
            id="mdl-conditions-login"
            openModal={this.state.openModal}
            onAccept={this.onAccept}
            onClose={this.onClose}
            onGTM={this.onGTM}
          ></Conditions>
        </div>
        <Footer />
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.security.userData,
  //validate user seguridad
  jwtAuthUser: state.security.jwtAuthUser,
  docNumber: state.docNumber,
  activeOrderTypes: state.activeOrderTypes,
  docType: state.docType,
});

const mapDispatchToProps = (dispatch) => ({
  //getOrders: (request) => dispatch(securityActions.getOrders(request)),
  sendFaultToken: (idFault) =>
    dispatch(securityActions.sendFaultToken(idFault)),
  sendProvisionToken: (idProvision) =>
    dispatch(securityActions.sendProvisionToken(idProvision)),

  setOrdeCode: (orderCode) => dispatch(securityActions.setOrdeCode(orderCode)),
  setDocNumber: (docNumber) =>
    dispatch(securityActions.setDocNumber(docNumber)),
  setActiveOrderTypes: (actAveOrderTypes) =>
    dispatch(securityActions.setActiveOrderTypes(actAveOrderTypes)),
  setDocType: (docType) => dispatch(securityActions.setDocType(docType)), //! aqui

  securityReset: () => dispatch(securityActions.securityReset()),
  provisionReset: () => dispatch(provisionActions.provisionReset()),
  scheduleReset: () => dispatch(scheduleActions.scheduleReset()),

  getApplicationParam: (request) =>
    dispatch(securityActions.getApplicationParam(request)),
  getApplicationParamTypes: (request) =>
    dispatch(securityActions.getApplicationParamTypes(request)),
  securityHideHeader: (value) =>
    dispatch(securityActions.securityHideHeader(value)),
  securityLoginTokenAcess: (request) =>
    dispatch(securityActions.securityLoginTokenAcess(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
