
import axios from 'axios';
import { ENDPOINTS } from './utils/Endpoints';

  let credencialsMemory = null;
  let bodyCredentials = null;

  // Función para obtener las keys de configuración
  const getKeysCredentials = (param) => {

    if (credencialsMemory) {  

      switch (param) {
        case 'SECURITY':
          bodyCredentials = {
            'Content-Type': 'application/json',
            'Authorization': credencialsMemory?.security?.Authorization || '', 
            'X-IBM-Client-Id': credencialsMemory?.security?.ClientId || '',
            'X-IBM-Client-Secret': credencialsMemory?.security?.ClientSecret || '',
            'Cookie': 'ARRAffinity=8ab1b306cb18f7c3281b2f881c632f014764ac2922817c21915d8038ea8e909a; ARRAffinitySameSite=8ab1b306cb18f7c3281b2f881c632f014764ac2922817c21915d8038ea8e909a; JSESSIONID=E2CA7F90D4E49C4A11009D24D037980E; JSESSIONID=813351858793EBD78CEAE8207E96C9A2'
          };
          break;
        
        case 'PROVISION':
          bodyCredentials = {
            'Content-Type': 'application/json',
            'Authorization': credencialsMemory?.provision?.Authorization || '', 
            'X-IBM-Client-Id': credencialsMemory?.provision?.ClientId || '',
            'X-IBM-Client-Secret': credencialsMemory?.provision?.ClientSecret || '',
          };
          break;
          
        case 'FAULT':
          bodyCredentials = {
            'Content-Type': 'application/json',
            'Authorization': credencialsMemory?.fault?.Authorization || '', 
            'X-IBM-Client-Id': credencialsMemory?.fault?.ClientId || '',
            'X-IBM-Client-Secret': credencialsMemory?.fault?.ClientSecret || '',
          };
          break;

        case 'SCHEDULE':
          bodyCredentials = {
            'Content-Type': 'application/json',
            'Authorization': credencialsMemory?.schedule?.Authorization || '', 
            'X-IBM-Client-Id': credencialsMemory?.schedule?.ClientId || '',
            'X-IBM-Client-Secret': credencialsMemory?.schedule?.ClientSecret || '',
          };
          break;      
      };

      return bodyCredentials;
    }

    return  axios.post(ENDPOINTS.BASE.SECURITY() + '/params/getCredentials').then( res => {      
      
      console.log("============= params/getCredentials =============")
      console.log(res)
      
      
      const data = res.data.body.content;      
      
      credencialsMemory = {
        security: {
          Authorization: data.ibmSecurity.auth,
          ClientId: data.ibmSecurity.clientId,
          ClientSecret: data.ibmSecurity.clientSecret,
        },
        schedule: {
          Authorization: data.ibmAgendamiento.auth,
          ClientId: data.ibmAgendamiento.clientId,
          ClientSecret: data.ibmAgendamiento.clientSecret,
        },
        fault: {
          Authorization: data.ibmAveria.auth,
          ClientId: data.ibmAveria.clientId,
          ClientSecret: data.ibmAveria.clientSecret,
        },
        provision: {
          Authorization: data.ibmProvision.auth,
          ClientId: data.ibmProvision.clientId,
          ClientSecret: data.ibmProvision.clientSecret,
        },
      };      

      console.log("============= credencialsMemory =============")


    }).catch(error => {
      console.log("============= ERROR getCredentials =============")
      console.log(error);

      return { status: 'ERROR' }
    });    
  };


  // header para los ajustes de seguridad de endpoint

  

export default { getKeysCredentials, credencialsMemory };
