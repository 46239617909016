import React, { Component } from 'react';
import iconMovistar from '../../resources/svg/login/iconLogo2.svg';
import './HeaderDelivery.scss';

export default ({}) => (
  <div id="headerDelivery">
    <div className="Wrapper-header">
      <img className="logo-movistar" src={iconMovistar} />
      <a href="https://movistarapp.page.link/home" className="buton-header">
        Descargar la app Mi Movistar
      </a>
    </div>
  </div>
);
