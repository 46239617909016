import React from 'react';
import './Alert.scss';
import iconClose from '../../resources/svg/login/iconClose.svg';
import iconAlert from '../../resources/svg/alertError/iconAlert.svg';
import iconAlertBlue from '../../resources/svg/alertError/iconAlertBlue.svg';
import iconMapaObservadaAlerta from '../../resources/svg/alertError/iconMapaObservadaAlerta.svg';
import Modal from 'react-responsive-modal';
import Button from '@material-ui/core/Button';

export default function Alert({
  openAlert,
  errorMsj,
  onClose,
  onAccept,
  maximoIntentos,
  alertBlue,
  localesTambo,
}) {
  return (
    <Modal
      classNames={{
        overlay: 'alert-overlay',
        modal: 'alert-modal',
      }}
      open={openAlert}
      onClose={onClose}
      showCloseIcon={null}
      center
    >
      <div
        id="errorModal"
        className="bxModalAlert d-flex flex-column justify-content-center align-items-center"
      >
        <div className="close-wrapper">
          <img
            id="img-close-conditions"
            className="icon-close"
            src={iconClose}
            onClick={onClose}
            alt=""
          />
        </div>
        <img
          className="llamada"
          src={alertBlue ? iconAlertBlue : iconMapaObservadaAlerta}
          alt=""
        />
        {localesTambo ? (
          <>
            <h3 className="title-localTambo">
              Locales Tambo no disponibles por <br /> el momento:
            </h3>
            <div className="container-listTambo">
              <ul>
                <li>TAMBO NAVARRETE - SAN ISIDRO</li>
                <li>TAMBO NAVARRETE - SAN ISIDRO</li>
                <li>TAMBO VALER - PUEBLO LIBRE</li>
                <li>TAMBO DAVINCI - SAN BORJA</li>
                <li>TAMBO LAPAZ - MIRAFLORES</li>
                <li>TAMBO ARENALES-C15 - LINCE</li>
                <li>TAMBO PRINCIPAL - SURQUILLO</li>
                <li>TAMBO ALMENDRAS-C3 - SMP</li>
                <li>TAMBO CANADA-C8 - LA VICTORIA</li>
                <li>TAMBO CLARK - SURCO</li>
                <li>TAMBO CORBETA - SURCO</li>
                <li>TAMBO PRINCIPAL - SURQUILLO</li>
                <li>TAMBO ALMENDRAS-C3 - SMP</li>
                <li>TAMBO CANADA-C8 - LA VICTORIA</li>
                <li>TAMBO CLARK - SURCO</li>
                <li>TAMBO CORBETA - SURCO</li>
                <li>TAMBO COSTAAZUL-C1 - CHORRILLOS</li>
                <li>TAMBO DELCAMPO - MAGDALENA</li>
              </ul>
            </div>
          </>
        ) : (
          <h2 id="lbl-title-modalerror" className="title">
            {maximoIntentos
              ? 'Máximo de intentos permitidos'
              : '¡Oh no, algo sucedió!'}
            {/* {'¡Se ha producido un error!'} */}
          </h2>
        )}

        <p
          id="lbl-detail-modalerror"
          className="detalle"
          dangerouslySetInnerHTML={{ __html: errorMsj }}
        />

        <div
          className="button-box"
          style={localesTambo ? { display: 'none' } : { display: 'flex' }}
        >
          <Button
            disableRipple
            id="btn-accept-modalerror"
            className="buttonAcept"
            variant="contained"
            color="primary"
            onClick={onAccept}
          >
            Volver al inicio
          </Button>
        </div>
      </div>
    </Modal>
  );
}
