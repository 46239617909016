import React, { Component } from 'react';
import { connect } from 'react-redux'; // Asegúrate de importar connect
import './TypesTracking.scss';
import { CONSTANTS } from '../../utils/Constants';
import Button from '@material-ui/core/Button';
import { Avatar } from '@material-ui/core';
import Footer from '../../components/common/elements/Footer';
import iconBack2 from '../../resources/svg/home/iconArrowBack.svg';
import iconArrowRigth from '../../resources/svg/home/iconArrowRight.svg';
import iconDelivery from '../../resources/svg/home/tracking/iconDelivery.svg';
import iconRoute from '../../resources/svg/home/tracking/iconRoute.svg';
import iconTechnicalService from '../../resources/svg/home/tracking/iconTechnicalService.svg';
import HeaderDelivery from '../headerDelivery/HeaderDelivery';

import * as securityActions from '../../store/actions/security.actions';
import * as securityReducer from '../../store/reducers/security.reducers';
import Loading from '../common/elements/Loading';
import Alert from '../modals/Alert';

class TypesTracking extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoading: false,
      openAlert: false,
      errorMsj: '',
      attemptsPerDay: true,
      showError: false,
      activeOrderTypes: [],
    };
  }

  componentDidMount() {
    this.setState({ activeOrderTypes: this.props.activeOrderTypes }, () => {});
  }

  onContinue = () => {
    let request = {
      documentType: this.props.docType,
      documentNumber: this.props.docNumber,
      source: 'WEB',
    };
    // Servicio que trae la lista de Provisiones y Avarías
    this.setState({ showLoading: true });
    this.props.setTraceId('PROVISION');
    this.props.securityLoginTokenAcess(request).then((resp) => {
      // responde ok cuando tiene una lista de provisiones o averias
      console.log('resp: ', resp);
      if (resp.status === 'OK') {
        this.setState({ showLoading: false });
        this.props.history.push({
          pathname: CONSTANTS.PATH.LIST,
        });
      } else if (resp.status === 'TOKEN') {
        /*
                / Servicio que envía el Token
                */
        let body = {
          traceId: resp.order.traceId,
          orderCode: resp.order.orderCode,
          jwt: this.props.jwtAuthUser,
        };
        console.log('body', body);
        if (
          resp.order.activityType.toLowerCase() ==
          CONSTANTS.PRODUCT.PROVISION_TYPE
        ) {
          this.props.sendProvisionToken(body).then((respToken) => {
            this.setState({ showLoading: false });
            console.log('respToken if: ', respToken);
            if (respToken.status === 'OK') {
              this.props.history.push({
                pathname: CONSTANTS.PATH.TOKEN,
              });
            } else if (respToken.status === 'NO_DATA') {
              this.setState({
                showError: true,
                errorMsj: respToken.message,
              });
            } else {
              this.setState({
                openAlert: true,
                errorMsj: respToken.message,
              });
            }
          });
        } else {
          this.props.sendFaultToken(body).then((respToken) => {
            console.log('respToken else: ', respToken);
            this.setState({ showLoading: false });

            if (respToken.status === 'OK') {
              this.props.history.push({
                pathname: CONSTANTS.PATH.TOKEN,
              });
            } else if (respToken.status === 'NO_DATA') {
              this.setState({
                showError: true,
                errorMsj: respToken.message,
              });
            } else if (respToken.status === 'ERROR') {
              this.setState({
                openAlert: true,
                errorMsj: respToken.message,
              });
            }
          });
        }
      } else if (resp.status === 'NO_DATA') {
        this.setState({
          showLoading: false,
        });
        this.props.history.push({
          pathname: CONSTANTS.PATH.SIN_PEDIDO,
        });
        console.log('esta entrando a NO_DATA');
      } else if (resp.status === 'ERROR') {
        this.setState({
          showLoading: false,
          openAlert: true,
          errorMsj: resp.message,
        });
      }
    });
  };

  onContinueDelivery = () => {
    let request = {
      documentType: this.props.docType,
      documentNumber: this.props.docNumber,
      traceType: 'delivery',
    };
    this.setState({ showLoading: true });
    this.props.setTraceId('DELIVERY');
    this.props.securityValidateAccess(request).then((resp) => {
      // responde ok cuando tiene una lista de provisiones o averias
      if (resp.status === 'OK') {
        this.setState({ showLoading: false });
        this.props.history.push({
          pathname: CONSTANTS.PATH.TOKEN,
        });
      } else if (resp.status === 'TOKEN') {
        /*
                / Servicio que envía el Token
                */
        let body = {
          traceId: resp.order.traceId,
          orderCode: resp.order.orderCode,
          jwt: this.props.jwtAuthUser,
        };
        console.log('body', body);
        if (
          resp.order.activityType.toLowerCase() ==
          CONSTANTS.PRODUCT.PROVISION_TYPE
        ) {
          this.props.sendProvisionToken(body).then((respToken) => {
            this.setState({ showLoading: false });
            console.log('respToken if: ', respToken);
            if (respToken.status === 'OK') {
              this.props.history.push({
                pathname: CONSTANTS.PATH.TOKEN,
              });
            } else if (respToken.status === 'NO_DATA') {
              this.setState({
                showError: true,
                errorMsj: respToken.message,
              });
            } else {
              this.setState({
                openAlert: true,
                errorMsj: respToken.message,
              });
            }
          });
        } else {
          this.props.sendFaultToken(body).then((respToken) => {
            console.log('respToken else: ', respToken);
            this.setState({ showLoading: false });

            if (respToken.status === 'OK') {
              this.props.history.push({
                pathname: CONSTANTS.PATH.TOKEN,
              });
            } else if (respToken.status === 'NO_DATA') {
              this.setState({
                showError: true,
                errorMsj: respToken.message,
              });
            } else if (respToken.status === 'ERROR') {
              this.setState({
                openAlert: true,
                errorMsj: respToken.message,
              });
            }
          });
        }
      } else if (resp.status === 'NO_DATA') {
        this.setState({
          showLoading: false,
        });
        this.props.history.push({
          pathname: CONSTANTS.PATH.SIN_PEDIDO,
        });
      } else if (resp.status === 'ERROR') {
        this.setState({
          showLoading: false,
          openAlert: true,
          errorMsj: resp.message,
        });
      }
    });
  };

  onAccept = () => {
    this.setState({
      openAlert: false,
    });

    if (this.state.attemptsPerDay) {
      this.props.history.push({
        pathname: CONSTANTS.PATH.LOGIN,
      });
    }
  };

  onCloseAlert = () => {
    this.setState({
      openAlert: false,
    });
  };

  back = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <section id="typesTracking">
        <Loading id="loading-login" showLoading={this.state.showLoading} />
        <HeaderDelivery />
        <div className="container-backButton">
          <div className="content-backButton">
            <Button
              disableRipple
              onClick={this.back}
              className="back-button"
              startIcon={<Avatar src={iconBack2} />}
            >
              Volver
            </Button>
          </div>
        </div>
        <div className="container">
          <div className="content-text">
            <h1>¡Bienvenido!</h1>
            <p>Selecciona la opción que prefieras:</p>
          </div>
          <div className="content-cards">
            {this.state.activeOrderTypes.includes('F') && (
              <div className="card" onClick={this.onContinueDelivery}>
                <div className="content-icon-card">
                  <img src={iconDelivery} alt="iconDelivery" />
                </div>
                <p>
                  Seguimiento de pedido de <br /> equipos y/o chip
                </p>
                <img src={iconArrowRigth} alt="iconArrowRigth" />
              </div>
            )}
            {this.state.activeOrderTypes.includes('P') && (
              <div className="card" onClick={this.onContinue}>
                <div className="content-icon-card">
                  <img src={iconRoute} alt="iconRoute" />
                </div>
                <p>
                  Seguimiento de instalación de
                  <br />
                  servicios
                </p>
                <img src={iconArrowRigth} alt="iconArrowRigth" />
              </div>
            )}
            {this.state.activeOrderTypes.includes('D') && (
              <div className="card" onClick={this.onContinue}>
                <div className="content-icon-card">
                  <img src={iconTechnicalService} alt="iconTechnicalService" />
                </div>
                <p>
                  Seguimiento de reparación de
                  <br /> avería
                </p>
                <img src={iconArrowRigth} alt="iconArrowRigth" />
              </div>
            )}
          </div>
        </div>
        <Alert
          id="mdl-error-token"
          openAlert={this.state.openAlert}
          errorMsj={this.state.errorMsj}
          onClose={this.onCloseAlert}
          onAccept={this.onAccept}
          alertBlue={true}
        />
        <Footer />
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  // tu mapStateToProps...
  docNumber: state.security.documentNumber,
  docType: state.security.documentType,
  jwtAuthUser: state.security.jwtAuthUser,
  user: state.security.userData,
  activeOrderTypes: state.security.activeOrderTypes,
  traceId: state.traceId,
});

const mapDispatchToProps = (dispatch) => ({
  // tu mapDispatchToProps...
  sendFaultToken: (idFault) =>
    dispatch(securityActions.sendFaultToken(idFault)),
  sendProvisionToken: (idProvision) =>
    dispatch(securityActions.sendProvisionToken(idProvision)),
  securityLoginTokenAcess: (request) =>
    dispatch(securityActions.securityLoginTokenAcess(request)),

  securityValidateAccess: (request) =>
    dispatch(securityActions.securityValidateAccess(request)),
  setTraceId: (traceId) => dispatch(securityActions.setTraceId(traceId)),
});

// export default TypesTracking
export default connect(mapStateToProps, mapDispatchToProps)(TypesTracking);
// export default connect(TypesTracking);
