import React, { Component } from 'react';
import { connect } from 'react-redux'; // Asegúrate de importar connect
import './DeliveryTracking.scss';
import { CONSTANTS } from '../../utils/Constants';
import Button from '@material-ui/core/Button';
import { Avatar } from '@material-ui/core';
import Footer from '../common/elements/Footer';
import iconBack2 from '../../resources/svg/home/iconArrowBack.svg';
import iconArrowRigth from '../../resources/svg/home/iconArrowRight.svg';
import checked2 from '../../resources/svg/deliveryTracking/checked2.svg';
import checkedError2 from '../../resources/svg/deliveryTracking/checkedError2.svg';
import HeaderDelivery from '../headerDelivery/HeaderDelivery';
import iconDetallePedido from '../../resources/svg/deliveryTracking/iconDetallePedido.svg';
import * as securityActions from '../../store/actions/security.actions';
import Loading from '../common/elements/Loading';

class DeliveryTracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      stateConfirmado: true,
      openAlert: false,
      attemptsPerDay: true,
      deliveryOrder: [this.props.deliveryOrder],
      deliveryOrderDetail: [this.props.orderDeliveryDetail],
      checkpoints: this.props.orderDeliveryDetail.deliveryOrder.checkpoints,
      checkePointCancelado: false,
      checkePointRechazado: false,
      checkePointEnCaminoReagendado: false,
      shippingType: '',
      shippingMessage: '',
    };
  }

  componentDidMount() {
    this.updateAgendadoOrCanceladoState();
    this.getShippingType();
  }

  onAccept = () => {
    this.setState({
      showLoading: true,
    });

    this.props.history.push({
      pathname: CONSTANTS.PATH.DELIVERY_DETAIL,
    });
  };

  back = () => {
    this.props.history.goBack();
  };

  formatDate(dateString) {
    const date = new Date(dateString);

    const options = {
      year: 'numeric',
      day: '2-digit',
      month: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };

    // Formateamos la fecha usando el locale 'es-ES' para obtener el formato dd/mm/yyyy
    let formattedDate = date.toLocaleString('es-ES', options);

    // Convertimos 'a. m.' o 'p. m.' a 'am' o 'pm'
    formattedDate = formattedDate.replace(/a\.\s*m\.|p\.\s*m\./i, (match) =>
      match.replace(/\.\s*/g, '').toLowerCase()
    );

    // Eliminamos la coma entre la fecha y la hora
    formattedDate = formattedDate.replace(',', '');

    return formattedDate;
  }

  capitalizeFirstLetter(string) {
    if (typeof string !== 'string' || string.length === 0) {
      return string;
    }
    if (string == 'ENPREPARACION') {
      return 'En preparación';
    }
    if (string == 'ENCAMINO') {
      return 'En camino';
    }
    if (string == 'LISTOPARARECOGER') {
      return 'Listo para recoger';
    }
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  updateAgendadoOrCanceladoState = () => {
    let existeDosEnCamino = false;
    const checkpoints =
      this.state.deliveryOrderDetail[0].deliveryOrder.checkpoints;
    const agendado = checkpoints.find((cp) => cp.status === 'AGENDADO');
    const cancelado = checkpoints.find((cp) => cp.status === 'CANCELADO');
    const statusCancelado = checkpoints.some((cp) => cp.status === 'CANCELADO');
    const statusRechazado = checkpoints.some((cp) => cp.status === 'RECHAZADO');

    // Contar cuántos checkpoints tienen el estado 'EN CAMINO'
    const enCaminoCount = checkpoints.filter(
      (cp) => cp.status === 'EN CAMINO'
    ).length;
    if (enCaminoCount > 1) {
      console.log('Existen 2 o más estados de "EN CAMINO"');
      existeDosEnCamino = true;
    }

    // Eliminar el primer duplicado porque puede existir 2 status de 'EN CAMINO'
    const seenStatuses = new Set();
    const updatedCheckpoints = checkpoints.filter((cp) => {
      if (seenStatuses.has(cp.status)) {
        return false; // Si ya hemos visto este estado, lo eliminamos
      }
      seenStatuses.add(cp.status); // Agregamos el estado a los vistos
      return true; // Si es el primer estado que vemos, lo mantenemos
    });

    let newDate = '';
    let newStateConfirmado = true;

    if (agendado) {
      newDate = this.formatDate(agendado.statusChangeDate);
    } else if (cancelado) {
      newDate = this.formatDate(cancelado.statusChangeDate);
      newStateConfirmado = false;
    } else {
      newStateConfirmado = 'ninguno';
    }

    this.setState({
      stateConfirmado: newStateConfirmado,
      // agendadoOrCanceladoDate: newDate,
      checkePointCancelado: statusCancelado,
      checkePointRechazado: statusRechazado,
      checkePointEnCaminoReagendado: existeDosEnCamino,
      checkpoints: updatedCheckpoints,
    });
  };

  getShippingType = () => {
    let shippingType =
      this.state.deliveryOrderDetail[0].deliveryOrder.shippingInstruction
        .shippingType;
    const shippingOption =
      this.state.deliveryOrderDetail[0].deliveryOrder.shippingInstruction
        .shippingOption;
    let shippingMessage;
    if (shippingType === 'REGULAR' || shippingType === 'EXPRESS') {
      shippingMessage = 'A domicilio';
    } else if (shippingType === 'PICKUP') {
      shippingMessage = 'Recojo en almacén';
    } else if (shippingType === 'TIENDA') {
      shippingMessage = 'Recojo en tambo';
    } else {
      shippingMessage = '...';
    }

    // este es un caso unico en el que si shippingOption no retorn un rango de horas, y lo que retorna
    // es "PICKUP", tomaremos ese valor como si fuera el tipo de entrega shippingType
    if (shippingOption === 'PICKUP') {
      shippingMessage = 'Recojo en almacén';
      shippingType = 'PICKUP';
    }

    this.setState({
      shippingType: shippingType,
      shippingMessage: shippingMessage,
    });
  };

  getPoints = () => {
    let points;
    if (this.state.shippingMessage == 'A domicilio') {
      points = ['Confirmado', 'En preparación', 'En camino', 'Entregado'];
    } else {
      points = [
        'Confirmado',
        'En preparación',
        'Listo para recoger',
        'Entregado',
      ];
    }

    const checkePoints = this.state.checkpoints;
    let isCancelado = false;
    let checkePointsRegistrados = [];

    checkePoints.map((cp) => {
      if (cp.status === 'CANCELADO' || cp.status === 'RECHAZADO') {
        isCancelado = true;
      } else {
        checkePointsRegistrados = [
          ...checkePointsRegistrados,
          this.capitalizeFirstLetter(cp.status),
        ];
      }
    });

    if (isCancelado === false) {
      const filteredPoints = points.filter(
        (point) => !checkePointsRegistrados.includes(point)
      );
      return filteredPoints;
    }
    if (isCancelado === true) {
      const filteredPoints = points.filter(
        (point) => !checkePointsRegistrados.includes(point)
      );
      filteredPoints.shift();
      return filteredPoints;
    }
  };

  getDeliveryDate(additionalData) {
    // Verificar si additionalData es un array
    if (!Array.isArray(additionalData)) {
      return '';
    }
    // Buscar el objeto con key 'deliveryDate'
    const deliveryDateObj = additionalData.find(
      (item) => item.key === 'deliveryDate'
    );
    // Retornar el valor si existe y no es null, de lo contrario retornar ''
    return deliveryDateObj && deliveryDateObj.value != null
      ? deliveryDateObj.value
      : '';
  }

  render() {
    return (
      <section id="deliveryTracking">
        <Loading id="loading-login" showLoading={this.state.showLoading} />
        <HeaderDelivery />
        <div className="container-backButton">
          <div className="content-backButton">
            <Button
              disableRipple
              onClick={this.back}
              className="back-button"
              startIcon={<Avatar src={iconBack2} />}
            >
              Volver
            </Button>
          </div>
        </div>
        <div className="container">
          <h1 className="title-deliveryTracking">Seguimiento de pedido</h1>
          <div className="content-text">
            <p>
              Tipo de entrega: <strong>{this.state.shippingMessage}</strong>{' '}
            </p>
            {this.state.shippingType !== 'EXPRESS' &&
              this.state.shippingType !== 'PICKUP' && (
                <p>
                  Entrega prevista:{' '}
                  <strong>
                    {this.getDeliveryDate(
                      this.state.deliveryOrderDetail[0].additionalData
                    )
                      .split('T')[0]
                      .split('-')
                      .reverse()
                      .join('/')}{' '}
                    de{' '}
                    {
                      this.state.deliveryOrderDetail[0].deliveryOrder
                        .shippingInstruction.shippingOption
                    }
                  </strong>{' '}
                </p>
              )}
          </div>
          <div className="container-tracking">
            {this.state.checkpoints.map((cp, index) => (
              <div
                key={index}
                className={`component-check-line ${
                  cp.status === 'CANCELADO' || cp.status === 'RECHAZADO'
                    ? 'component-check-line-red'
                    : ''
                }`}
              >
                <div
                  className="container-line"
                  style={{
                    display: cp.status === 'REGISTRADO' ? 'none' : 'block',
                  }}
                >
                  <div className="linea-div"></div>
                </div>
                <div className="container-check-text">
                  <div className="container-check">
                    <div className="background-check"></div>
                    <img
                      src={
                        cp.status === 'CANCELADO' || cp.status === 'RECHAZADO'
                          ? checkedError2
                          : checked2
                      }
                      alt="checked"
                    />
                  </div>
                  <div className="container-text">
                    <p className="text">
                      {this.capitalizeFirstLetter(cp.status)}{' '}
                      {this.capitalizeFirstLetter(cp.status) === 'En camino' &&
                      this.state.checkePointEnCaminoReagendado === true ? (
                        <span className="classReagendado">Reagendado</span>
                      ) : (
                        <span>{this.formatDate(cp.statusChangeDate)}</span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            ))}
            {this.getPoints().map((point, index) => (
              <div
                key={index}
                className={`component-point-line ${
                  this.state.checkePointCancelado == true ||
                  this.state.checkePointRechazado == true
                    ? 'component-point-line-red'
                    : ''
                }`}
              >
                <div className="container-line">
                  <div className="linea-div"></div>
                </div>
                <div className="container-check-text">
                  <div className="container-check">
                    <div className="point"></div>
                  </div>
                  <div className="container-text">
                    <p className="text">{point}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="content-detalleButton" onClick={this.onAccept}>
            <img src={iconDetallePedido} alt="iconDetallePedido" />
            <p>Detalles de mi pedido</p>
            <img
              src={iconArrowRigth}
              alt="iconArrowRigth"
              className="iconArrowRigth"
            />
          </div>
        </div>
        <Footer />
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  // tu mapStateToProps...
  deliveryOrder: state.security.deliveryOrder,
  orderDeliveryDetail: state.security.orderDeliveryDetail,
});

const mapDispatchToProps = (dispatch) => ({
  // tu mapDispatchToProps...
});

// export default DeliveryTracking
export default connect(mapStateToProps, mapDispatchToProps)(DeliveryTracking);
// export default connect(DeliveryTracking);
