import React, { Component } from 'react';
import './Token.scss';
import { connect } from 'react-redux';
import Timer from 'react-compound-timer';
import { CONSTANTS } from '../../utils/Constants';

import Button from '@material-ui/core/Button';

import * as faultActions from '../../store/actions/averia.actions';
import * as securityActions from '../../store/actions/security.actions';
import * as provisionActions from '../../store/actions/provision.actions';
import * as scheduleActions from '../../store/actions/schedule.actions';
import * as commonSelectors from '../../store/selectors/common.selectors';

import iconSMS from '../../resources/svg/token/iconSMS.svg';
import iconBack from '../../resources/svg/token/iconBack.svg';
import iconBack2 from '../../resources/svg/home/iconArrowBack.svg';
import backgroundWeb from '../../resources/svg/login/backgroundWeb.svg';
import iconMovistar from '../../resources/svg/login/iconLogo.svg';

import TokenExternal from './external/TokenExternal';
import Loading from '../../components/common/elements/Loading';
import Footer from '../../components/common/elements/Footer';
import Alert from '../modals/Alert';
import { Avatar } from '@material-ui/core';
import HeaderDelivery from '../headerDelivery/HeaderDelivery';

class Token extends Component {
  constructor(props) {
    super(props);

    this.state = {
      attemptsPerDay: false,
      codigo: '',
      validacion: false,
      open: false,
      openAlert: false,
      openModal: false,
      errorMsj: '',
      showError: false,
      showErrorModal: false,
      showTimer: true,
      resend: '',
      showCheck: true,
      showLoading: false,
      stoppedTimer: false,
      showRedMessage: false,
      showGreyMessage: false,
      resendToken: false,
      inputType: '',
      disabledContinue: true,
      value1: '',
      value2: '',
      value3: '',
      value4: '',
      inputHasContent: [false, false, false, false],
      intentos: 4,
      maximoIntentos: false,
      traceId: '',
    };
  }

  componentDidMount() {
    window.dataLayer.push({
      event: 'atm.event',
      eventCategory: 'page view',
      eventAction: 'view',
      eventLabel: 'token',
      path: this.props.history.location.pathname,
      nonInteraction: '1',
      //datos genéricos
      userId: this.props.user.documentNumber,
      tipoCliente: this.props.user.documentType.toLowerCase(),
      numero: '',
      // productos: this.props.orderDetail.productName
      //   ? this.props.orderDetail.productName.toLowerCase().trim()
      //   : '',
      productos:
        this.props.orderDetail && this.props.orderDetail.productName
          ? this.props.orderDetail.productName.toLowerCase().trim()
          : '',
    });

    // if (this.props.userToken !== null) {
    //     this.setState({ validacion: true, codigo:  this.props.userToken});
    //     let data = Array.from(this.props.userToken);
    //     data.map((item, index) => {
    //         document.getElementById(`item${index + 1}`).value = item;
    //     });
    // } else {
    //    let elemento = document.getElementById("form").childNodes[0];
    //    elemento.focus();
    // }
  }

  errorMensajeModal = (mensaje, estado) => {
    this.setState({
      errorMsj: mensaje,
      showErrorModal: estado,
      showError: false,
      resendToken: false,
    });
  };

  errorMensaje = (mensaje, estado) => {
    this.setState({
      errorMsj: mensaje,
      showError: estado,
      showRedMessage: true,
      resendToken: false,
    });
  };

  onCloseAlert = () => {
    this.setState({
      openAlert: false,
    });
  };

  onAccept = () => {
    this.setState({
      openAlert: false,
    });

    if (this.state.attemptsPerDay) {
      this.props.history.push({
        pathname: CONSTANTS.PATH.LOGIN,
      });
    }
  };

  completeTime = (valueX) => {
    if (valueX === 0 && this.state.stoppedTimer === false) {
      this.setState({
        stoppedTimer: true,
      });
    }
    return valueX;
  };

  completeTwoDigits = (valor) => {
    if (
      this.state.stoppedTimer &&
      valor === 0 &&
      this.state.showCheck === true
    ) {
      this.setState({
        showCheck: false,
        showTimer: false,
      });
    }
    return valor.toString().length === 1 ? '0' + valor : valor;
  };

  handleChange(event) {
    var item1 = document.getElementById('item1').value;
    var item2 = document.getElementById('item2').value;
    var item3 = document.getElementById('item3').value;
    var item4 = document.getElementById('item4').value;
    const value1 = item1.replace('.', '');
    const value2 = item2.replace('.', '');
    const value3 = item3.replace('.', '');
    const value4 = item4.replace('.', '');

    this.setState({
      codigo: value1 + value2 + value3 + value4,
      value1: value1,
      value2: value2,
      value3: value3,
      value4: value4,
    });
  }

  onChange = (e) => {
    var item1 = document.getElementById('item1').value;
    var item2 = document.getElementById('item2').value;
    var item3 = document.getElementById('item3').value;
    var item4 = document.getElementById('item4').value;

    var value1 = item1.replace('.', '');
    value1 = value1.replace(',', '');
    value1 = value1.replace('-', '');

    var value2 = item2.replace('.', '');
    value2 = value2.replace(',', '');
    value2 = value2.replace('-', '');

    var value3 = item3.replace('.', '');
    value3 = value3.replace(',', '');
    value3 = value3.replace('-', '');

    var value4 = item4.replace('.', '');
    value4 = value4.replace(',', '');
    value4 = value4.replace('-', '');

    if (item1.length > 1) {
      value1 = this.state.value1;
      document.getElementById('item1').value = this.state.value1;
    }
    if (item2.length > 1) {
      value2 = this.state.value2;
      document.getElementById('item2').value = this.state.value2;
    }
    if (item3.length > 1) {
      value3 = this.state.value3;
      document.getElementById('item3').value = this.state.value3;
    }
    if (item4.length > 1) {
      value4 = this.state.value4;
      document.getElementById('item4').value = this.state.value4;
    }

    let newInputHasContent = [
      value1 !== '',
      value2 !== '',
      value3 !== '',
      value4 !== '',
    ];

    this.setState(
      {
        codigo: value1 + value2 + value3 + value4,
        value1: value1,
        value2: value2,
        value3: value3,
        value4: value4,
        inputHasContent: newInputHasContent,
      },
      () => {
        this.setState({
          validacion: this.state.codigo.length === 4,
        });
        if (this.state.codigo.length === 4) {
          document.getElementById('item4').blur();
        }
      }
    );

    //Se limpian los mensajes de error y reenvío
    this.setState({
      showGreyMessage: false,
      showRedMessage: false,
      showError: false,
    });
  };

  onKeyUp = (e) => {
    var key = e.which;

    let itemSiguiente =
      parseInt(
        e.target.id.substring(e.target.id.length - 1, e.target.id.length)
      ) + 1;

    if (itemSiguiente <= 4 && itemSiguiente > 0) {
      if (key !== 8) {
        if ((key >= 48 || key <= 57) && key !== 229) {
          if (key >= 95 && key <= 106) {
            document.getElementById('item' + itemSiguiente).focus();
          }
        }
      }
    }
  };

  validainput = (e) => {
    this.goToNextInput(e);
  };

  goToNextInput = (e) => {
    var id = e.target.id;
    var key = e.which;

    let t = e.target;
    let sib = t.nextSibling;
    let pre = t.previousSibling;

    if (key === 8) {
      if (e.target.value == null || e.target.value == '') {
        document.getElementById(id).innerText = '';
        this.setState({
          codigo: this.state.codigo.substring(
            this.state.codigo.length,
            this.state.codigo.length - 1
          ),
          validacion: false,
        });
      }
    } else if ((key < 48 || key > 57) && key !== 229) {
      if (key < 96 || key > 106) {
        document.getElementById(id).innerText = '';
        e.preventDefault();

        return false;
      }
    }
    if (key === 9) {
      return true;
    }

    if (e.target.value && key !== 8 && 'item4' !== e.target.id) {
      sib.focus();
    } else if (!e.target.value && key === 8 && 'item1' !== e.target.id) {
      pre.focus();
    } else {
    }
  };

  onClick = (e) => {
    this.setState({
      showGreyMessage: false,
      showRedMessage: false,
    });
  };

  validateToken() {
    this.setState({ showLoading: true, attemptsPerDay: false });
    console.log('codigo: ', this.state.codigo);
    this.props.userValidToken(this.state.codigo).then((resp) => {
      // console.log('validateToken resp: ', resp);
      if (resp.status === 'OK') {
        if (
          this.props.orderType &&
          this.props.orderType.toLowerCase() ===
            CONSTANTS.PRODUCT.PROVISION_TYPE
        ) {
          // llego aqui porque tiene una orden o post page List
          let request = {
            documentNumber: this.props.docNumber,
            orderCode: this.props.orderCode,
          };

          this.props.getProvisionDetail(request).then((resp) => {
            if (resp.status == 'OK') {
              this.apiGetShedule();
            } else {
              if (resp.status == 'ERROR_SECURITY') {
                this.props.history.push({
                  pathname: CONSTANTS.PATH.LOGIN,
                });
              } else {
                this.setState({
                  showLoading: false,
                  errorMsj: resp.message,
                  openAlert: true,
                });
              }
            }
          });
        } else if (this.props.traceId == 'DELIVERY') {
          // console.log('entra a delivery');
          // this.apiGetOrderDelivery();
          let request = {
            documentNumber: this.props.docNumber,
            documentType: this.props.docType,
          };

          this.props.getDeliveryOrder(request).then((resp) => {
            if (resp && resp.status == 'OK') {
              this.props.setOrderDeliveryList(resp.data);
              this.props.history.push({
                pathname: CONSTANTS.PATH.DELIVERY_ORDER,
              });
            } else {
              if (resp && resp.status == 'ERROR_SECURITY') {
                this.props.history.push({
                  pathname: CONSTANTS.PATH.LOGIN,
                });
              } else {
                this.setState({
                  attemptsPerDay: true,
                  showLoading: false,
                  errorMsj:
                    'No se ha podido establecer conexión con el servidor. Por favor, vuelve a intentarlo más tarde.',
                  openAlert: true,
                });
              }
            }
          });
        } else {
          // --------------------------- averia ------------------------------ //

          let request = {
            documentType: this.props.user.documentType,
            documentNumber: this.props.docNumber,
            orderCode: this.props.orderCode,
          };

          this.props.getFaultDetail(request).then((resp) => {
            if (resp.status == 'OK') {
              this.apiGetSheduleFault();
            } else {
              // adecuacion de seguridad
              if (resp.status == 'ERROR_SECURITY') {
                this.props.history.push({
                  pathname: CONSTANTS.PATH.LOGIN,
                });
              } else {
                this.setState({
                  showLoading: false,
                  errorMsj: resp.message,
                  openAlert: true,
                });
              }
            }
          });
        }
      } else if (resp.status === 'NO_DATA') {
        //codigo SMS incorrecto
        this.setState({
          showLoading: false,
          errorMsj: resp.message,
          showError: true,
          showRedMessage: true,
          resendToken: false,
          showGreyMessage: false,
          intentos: this.state.intentos - 1,
        });
        console.log('intentos: ', this.state.intentos);
      } else if (resp.status === 'NO_VALID') {
        //Cantidad de intentos por dia
        this.setState({
          showLoading: false,
          attemptsPerDay: true,
          errorMsj: resp.message,
          openAlert: true,
          maximoIntentos: true,
        });
      } else if (resp.status === 'ERROR') {
        //Error de servidor
        this.setState({
          showLoading: false,
          errorMsj: resp.message,
          openAlert: true,
        });
      }
    });
  }

  /* apiGetProvision = () => {

        this.props.getOrders().then(response => {

            if (response.status === 'OK') {
                if(response.data.length === 1 ){
                    this.props.setTypeScheduleSource(response.data[0].activityType);
                    if(response.data[0].activityType === "provision"){
                        this.apiGetShedule();
                    }else {
                        this.apiGetSheduleFault(response.data);
                    }
                } else {
                    this.props.history.push({
                        pathname: CONSTANTS.PATH.LIST
                    });
                }
  
            } else {
                this.setState({
                     showLoading: false, 
                     attemptsPerDay: true,
                     errorMsj: response.message,
                     openAlert : true
                })
            }
        })
        
    } */

  apiGetShedule = () => {
    this.props.getScheduledOrder().then((resp) => {
      if (resp.status === 'OK') {
        /* ===== Política de días de reagendamiento ===== */
        this.props
          .getApplicationParam(CONSTANTS.PARAM.PARAM_RESCHEDULE)
          .then((response) => {
            if (response.status === 'OK') {
              this.props.setSecurityReschedulePolicy(
                response.data.content.value
              );
            } else {
              this.props.setSecurityReschedulePolicy('1');
            }
          });

        this.props.history.push({
          pathname: CONSTANTS.PATH.HOME,
        });
      } else {
        this.setState({
          showLoading: false,
          attemptsPerDay: true,
          errorMsj: resp.message,
          openAlert: true,
        });

        // agregar logout
      }
    });
  };

  apiGetOrderDelivery = () => {
    this.props.getScheduledOrder().then((resp) => {
      if (resp.status === 'OK') {
        /* ===== Política de días de reagendamiento ===== */
        this.props
          .getApplicationParam(CONSTANTS.PARAM.PARAM_RESCHEDULE)
          .then((response) => {
            if (response.status === 'OK') {
              this.props.setSecurityReschedulePolicy(
                response.data.content.value
              );
            } else {
              this.props.setSecurityReschedulePolicy('1');
            }
          });

        this.props.history.push({
          pathname: CONSTANTS.PATH.DELIVERY_ORDER,
        });
      } else {
        this.setState({
          showLoading: false,
          attemptsPerDay: true,
          errorMsj: resp.message,
          openAlert: true,
        });

        // agregar logout
      }
    });
  };

  apiGetSheduleFault = () => {
    this.props.getScheduledFault().then((resp) => {
      if (resp.status === 'OK') {
        //ingresa a averias
        this.props.history.push({
          pathname: CONSTANTS.PATH.FAULT,
        });
      } else {
        // logout
        if (resp.status == 'ERROR_SECURITY') {
          this.props.history.push({
            pathname: CONSTANTS.PATH.LOGIN,
          });
        } else {
          this.setState({
            showLoading: false,
            attemptsPerDay: true,
            errorMsj: resp.message,
            openAlert: true,
          });
        }
      }
    });
  };

  nextpage = () => {
    window.dataLayer.push({
      event: 'atm.event',
      eventCategory: 'token',
      eventAction: 'click',
      eventLabel: 'ingresar',
      nonInteraction: '1',
      //datos genéricos
      userId: this.props.user.documentNumber,
      tipoCliente: this.props.user.documentType.toLowerCase(),
      numero: '',
      // productos: this.props.orderDetail.productName
      //   ? this.props.orderDetail.productName.toLowerCase().trim()
      //   : '',
      productos:
        this.props.orderDetail && this.props.orderDetail.productName
          ? this.props.orderDetail.productName.toLowerCase().trim()
          : '',
    });

    this.validateToken();
  };

  onlyNumbers = (e) => {
    var key = window.event ? e.which : e.keyCode;
    if (key < 48 || key > 57) {
      e.preventDefault();
    }
  };

  clean = () => {
    document.getElementById('item1').value = '';
    document.getElementById('item2').value = '';
    document.getElementById('item3').value = '';
    document.getElementById('item4').value = '';
    this.setState({
      value1: '',
      value2: '',
      value3: '',
      value4: '',
      codigo: '',
    });
  };

  resendToken() {
    // this.setState({ showLoading: true });
    if (
      this.props.orderType.toLowerCase() === CONSTANTS.PRODUCT.PROVISION_TYPE
    ) {
      this.props
        .sendProvisionToken(this.props.orderDetail.idProvision)
        .then((response) => {
          this.setState({ showLoading: false });

          if (response.status === 'OK') {
            document.getElementById('item1').focus();
            this.errorMensaje(response.message, true, true);

            //ACTIVAR TIMER
            this.setState({
              showTimer: true,
              showCheck: true,
              stoppedTimer: false,
              showError: false,
              resendToken: true,
              showGreyMessage: true,
              showRedMessage: false,
            });
          } else if (response.status === 'NO_DATA') {
            this.setState({
              attemptsPerDay: true,
              errorMsj: response.message,
              openAlert: true,
            });
          } else {
            this.clean();
            this.setState({
              showGreyMessage: false,
              showRedMessage: false,
              resendToken: false,
              openAlert: true,
              errorMsj: response.message,
              showError: false,
              resendToken: false,
            });
          }
        });
    } else {
      this.props
        .sendFaultToken(this.props.orderDetail.idFault)
        .then((response) => {
          this.setState({ showLoading: false });

          if (response.status === 'OK') {
            document.getElementById('item1').focus();
            this.errorMensaje(response.message, true, true);

            //ACTIVAR TIMER
            this.setState({
              showTimer: true,
              showCheck: true,
              stoppedTimer: false,
              showError: false,
              resendToken: true,
              showGreyMessage: true,
              showRedMessage: false,
            });
          } else if (response.status === 'NO_VALID') {
            this.clean();
            this.setState({
              attemptsPerDay: true,
              errorMsj: response.message,
              openAlert: true,
            });
          } else {
            this.clean();
            this.setState({
              showGreyMessage: false,
              showRedMessage: false,
              resendToken: false,
              openAlert: true,
              errorMsj: response.message,
              showError: false,
              resendToken: false,
            });
          }
        });
    }

    // this.props.userResendToken().then(resp=> {
    //     this.setState({ showLoading: false });
    //     if (resp.status === 'OK') {
    //         document.getElementById('item1').focus();
    //         this.errorMensaje(resp.message, true, true);

    //         //ACTIVAR TIMER
    //         this.setState({
    //             showTimer: true,
    //             showCheck: true,
    //             stoppedTimer: false,
    //             showError:false,
    //             resendToken: true,
    //             showGreyMessage:true,
    //             showRedMessage:false
    //         })
    //        }
    //        else if (resp.status === 'NO_VALID') {
    //             //Cantidad de intentos por dia
    //            //this.errorMensajeModal(resp.message, true);
    //             this.setState({
    //                 //validateNumberDoc: true
    //                  showLoading: false,
    //                  attemptsPerDay: true,
    //                  errorMsj: resp.message,
    //                  openAlert : true
    //             })
    //     }else if (resp.status === 'ERROR') {
    //         this.errorMensajeModal(resp.message, true);
    //         this.clean();
    //         this.setState({
    //             showGreyMessage: false,
    //             showRedMessage: false,
    //             resendToken: false
    //         })
    //     } else {
    //         this.setState({ showLoading: false, attemptsPerDay: true });
    //         this.errorMensajeModal(resp.message, true);
    //         this.setState({
    //             showGreyMessage: false,
    //             showRedMessage: false,
    //             resendToken:false
    //         })
    //     }
    // });
  }

  repass = () => {
    window.dataLayer.push({
      event: 'atm.event',
      eventCategory: 'token',
      eventAction: 'click',
      eventLabel: 'reenviar clave',
      nonInteraction: '1',

      //datos genéricos
      userId: this.props.user.documentNumber,
      tipoCliente: this.props.user.documentType.toLowerCase(),
      numero: '',
      // productos: this.props.orderDetail.productName
      //   ? this.props.orderDetail.productName.toLowerCase().trim()
      //   : '',
      productos:
        this.props.orderDetail && this.props.orderDetail.productName
          ? this.props.orderDetail.productName.toLowerCase().trim()
          : '',
    });

    this.clean();

    //Se valida el tiempo límete de sesión

    if (commonSelectors.validSessionTime(this.props.userLoginTime)) {
      this.resendToken();
    } else {
      this.props.history.push({
        pathname: CONSTANTS.PATH.BASENAME + CONSTANTS.PATH.LOGIN,
      });
    }
  };

  back = () => {
    window.dataLayer.push({
      event: 'atm.event',
      eventCategory: 'token',
      eventAction: 'click',
      eventLabel: 'volver',
      nonInteraction: '1',

      //datos genéricos
      userId: this.props.user.documentNumber,
      tipoCliente: this.props.user.documentType.toLowerCase(),
      numero: '',
      // productos: this.props.orderDetail.productName
      //   ? this.props.orderDetail.productName.toLowerCase().trim()
      //   : '',
      productos:
        this.props.orderDetail && this.props.orderDetail.productName
          ? this.props.orderDetail.productName.toLowerCase().trim()
          : '',
    });

    this.props.history.goBack();
  };

  // onGetPrivatePhone = () => {
  //   let phone = this.props.orderToken.phone;
  //   //desde backend llega con ´*´
  //   /* if(phone != null){
  //           if(phone.toString().length == 9){
  //               phone = `${phone.toString().substring(0,1)}** *** ${phone.toString().substring(6,9)}`;
  //           }
  //       } */
  //   return phone;
  // };
  onGetPrivatePhone = () => {
    const { orderToken } = this.props;
    if (!orderToken || !orderToken.phone) {
      return ''; // O cualquier valor por defecto que desees mostrar
    }
    let phone = orderToken.phone;
    //desde backend llega con ´*´
    /* if(phone != null){
            if(phone.toString().length == 9){
                phone = `${phone.toString().substring(0,1)}** *** ${phone.toString().substring(6,9)}`;
            }
        } */
    return phone;
  };

  render() {
    const { open } = this.state;
    const breakpoints = {
      desktop: 1040,
      tablet: 840,
      mobile: 540,
    };

    if (window.innerWidth > breakpoints.tablet) {
      this.state.inputType = 'text';
    } else if (window.innerWidth > breakpoints.mobile) {
      this.state.inputType = 'number';
    } else if (window.innerWidth <= breakpoints.mobile) {
      this.state.inputType = 'number';
    }
    const { item1, item2, item3, item4 } = this.state;

    return (
      <section id="token">
        <HeaderDelivery />
        <div className="container-backButton">
          <div className="content-backButton">
            <Button
              disableRipple
              onClick={this.back}
              // id="btn-back-token"
              className="back-button"
              // variant="outlined"
              // startIcon={<Avatar src={iconBack} />}
              // endIcon={<Avatar src={iconBack} />}
              startIcon={<Avatar src={iconBack2} />}
            >
              Volver
            </Button>
          </div>
        </div>
        <div className="container">
          <div className="content-body">
            <div className="bxgroup">
              <div id="lbl-title-token" className="section-title">
                <h1 className="titleSingle">Ingresa el código que enviamos</h1>
                <p>
                  Por mensaje de texto al número que registraste al contratar el
                  servicio
                </p>
                {/* <br /> */}
                <span>{this.onGetPrivatePhone()}</span>
              </div>

              <div className="boxToken">
                <div id="form">
                  <input
                    // className={this.state.showError ? 'input-error' : null}
                    className={`
                      ${this.state.inputHasContent[0] ? 'has-content' : ''}
                      ${this.state.showError ? 'input-error' : ''}
                    `}
                    id="item1"
                    onKeyPress={(e) => this.onlyNumbers(e)}
                    onChange={(e) => this.onChange(e)}
                    onKeyUp={(e) => this.onKeyUp(e)}
                    onKeyDown={(e) => this.validainput(e)}
                    onInput={this.handleChange.bind(this)}
                    type={this.state.inputType}
                    autoComplete="off"
                    maxLength="1"
                    size="1"
                    min="0"
                    max="9"
                    pattern="[0-9]{1}"
                    onClick={(e) => this.onClick(e)}
                  />
                  <input
                    // className={this.state.showError ? 'input-error' : null}
                    className={`
                      ${this.state.inputHasContent[1] ? 'has-content' : ''}
                      ${this.state.showError ? 'input-error' : ''}
                    `}
                    id="item2"
                    onKeyPress={(e) => this.onlyNumbers(e)}
                    onChange={(e) => this.onChange(e)}
                    onKeyUp={(e) => this.onKeyUp(e)}
                    onKeyDown={(e) => this.validainput(e)}
                    onInput={this.handleChange.bind(this)}
                    type={this.state.inputType}
                    autoComplete="off"
                    maxLength="1"
                    size="1"
                    min="0"
                    max="9"
                    pattern="[0-9]{1}"
                    onClick={(e) => this.onClick(e)}
                  />
                  <input
                    // className={this.state.showError ? 'input-error' : null}
                    className={`
                      ${this.state.inputHasContent[2] ? 'has-content' : ''}
                      ${this.state.showError ? 'input-error' : ''}
                    `}
                    id="item3"
                    onKeyPress={(e) => this.onlyNumbers(e)}
                    onChange={(e) => this.onChange(e)}
                    onKeyUp={(e) => this.onKeyUp(e)}
                    onKeyDown={(e) => this.validainput(e)}
                    onInput={this.handleChange.bind(this)}
                    type={this.state.inputType}
                    autoComplete="off"
                    maxLength="1"
                    size="1"
                    min="0"
                    max="9"
                    pattern="[0-9]{1}"
                    onClick={(e) => this.onClick(e)}
                  />
                  <input
                    // className={this.state.showError ? 'input-error' : null}
                    className={`
                      ${this.state.inputHasContent[3] ? 'has-content' : ''}
                      ${this.state.showError ? 'input-error' : ''}
                    `}
                    id="item4"
                    onKeyPress={(e) => this.onlyNumbers(e)}
                    onChange={(e) => this.onChange(e)}
                    onKeyUp={(e) => this.onKeyUp(e)}
                    onKeyDown={(e) => this.validainput(e)}
                    onInput={this.handleChange.bind(this)}
                    type={this.state.inputType}
                    autoComplete="off"
                    maxLength="1"
                    size="1"
                    min="0"
                    max="9"
                    pattern="[0-9]{1}"
                    onClick={(e) => this.onClick(e)}
                  />
                </div>

                <div className="box-error">
                  {/* <p id="lbl-invalidpass-token" className="errorlabel full">
                    {this.state.showError ? 'Código incorrecto' : ''}
                  </p> */}
                  <p id="lbl-invalidpass-token" className="errorlabel full">
                    {this.state.showError
                      ? 'Código incorrecto'
                      : this.state.intentos < 1
                      ? ''
                      : this.state.intentos < 4
                      ? `Tienes ${this.state.intentos} intentos más`
                      : ''}
                  </p>
                </div>

                {this.state.resendToken && (
                  <p id="lbl-sentpass-token" className="resendlabel">
                    Clave de seguridad enviada
                  </p>
                )}

                <div className="bxReenviar">
                  <a
                    id="lnk-resend-token"
                    onClick={this.state.showCheck ? null : this.repass}
                    className={this.state.showCheck ? 'disabledTxr' : 'txr'}
                  >
                    <u>Reenviar código </u>
                  </a>

                  {this.state.showTimer && (
                    <Timer
                      initialTime={
                        this.props.orderToken.sendCount != null
                          ? this.props.orderToken.sendCount
                          : CONSTANTS.PARAM.DEFAULT_TIMER
                      }
                      direction="backward"
                      startImmediately={true}
                    >
                      {() => (
                        <div className="timerlabel">
                          <React.Fragment>
                            <Timer.Minutes
                              formatValue={(valueX) =>
                                `en 0${this.completeTime(valueX)}:`
                              }
                            />
                            <Timer.Seconds
                              formatValue={(value) =>
                                `${this.completeTwoDigits(value)}`
                              }
                            />
                          </React.Fragment>
                        </div>
                      )}
                    </Timer>
                  )}
                </div>

                <div className="box-buttons">
                  <Button
                    id="btn-enter-token"
                    // className="enter-button"
                    className={`enter-button ${
                      this.state.showLoading ? 'showLoading' : ''
                    }`}
                    variant="contained"
                    onClick={this.nextpage}
                    disabled={!this.state.validacion}
                    // style={{ opacity: !this.state.validacion ? 0.4 : 1 }}

                    style={{
                      backgroundColor: this.state.showLoading
                        ? '#A9B3B9'
                        : !this.state.validacion
                        ? '#A9B3B9'
                        : '#019BEF',
                    }}
                  >
                    {this.state.showLoading && <span class="loader"></span>}
                    Verificar
                  </Button>
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>

        <Alert
          id="mdl-error-token"
          openAlert={this.state.openAlert}
          errorMsj={this.state.errorMsj}
          onClose={this.onAccept}
          onAccept={this.onAccept}
          maximoIntentos={this.state.maximoIntentos}
        />
        <div className="footer-token">
          <Footer />
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orderDetail: state.security.orderDetail,
    orderToken: state.security.orderToken,
    orderList: state.security.orderList,
    orderType: state.security.orderType,

    userLoginTime: state.security.userLoginTime,

    user: state.security.userData,
    userToken: state.security.userLoginToken,
    validacion: state.security.validacion,

    averia: state.security.averia,
    timerLimit: state.security.userTimer,

    webView: state.security.userWebView,

    //config seguridad
    orderCode: state.security.orderCode,
    docNumber: state.security.documentNumber,
    docType: state.security.documentType,
    // Agregar el token al mapeo
    authToken: state.security.authToken,

    //validate user seguridad
    jwtAuthUser: state.security.jwtAuthUser,

    traceId: state.security.traceId,

    orderDeliveryList: state.orderDeliveryList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendFaultToken: (idFault) =>
      dispatch(securityActions.sendFaultToken(idFault)),
    sendProvisionToken: (idProvision) =>
      dispatch(securityActions.sendProvisionToken(idProvision)),

    setAuthToken: (token) => dispatch(securityActions.setAuthToken(token)),

    setOrderDeliveryList: (orderDeliveryList) =>
      dispatch(securityActions.setOrderDeliveryList(orderDeliveryList)),

    userValidToken: (code) => dispatch(securityActions.userValidToken(code)),
    //userResendToken : () => dispatch(securityActions.userResendToken()),
    getApplicationParam: (request) =>
      dispatch(securityActions.getApplicationParam(request)),
    setSecurityReschedulePolicy: (value) =>
      dispatch(securityActions.setSecurityReschedulePolicy(value)),
    getOrders: () => dispatch(securityActions.getOrders()),
    getTechnicianInfo: () => dispatch(scheduleActions.getTechnicianInfo()),

    getProvision: () => dispatch(provisionActions.getProvision()),

    getScheduledOrder: () => dispatch(scheduleActions.getScheduledOrder()),
    getScheduledFault: () => dispatch(scheduleActions.getScheduledFault()),
    setTypeScheduleSource: (typeScheduleSource) =>
      dispatch(scheduleActions.setTypeScheduleSource(typeScheduleSource)),
    getProvisionDetail: (request) =>
      dispatch(securityActions.getProvisionDetail(request)),
    getFaultDetail: (request) =>
      dispatch(securityActions.getFaultDetail(request)),

    getDeliveryOrder: (request) =>
      dispatch(securityActions.getDeliveryOrder(request)),
  };
};

//export default Token;

export default connect(mapStateToProps, mapDispatchToProps)(Token);
