import React, { Component } from 'react';
import { connect } from 'react-redux'; // Asegúrate de importar connect
import './SinPedido.scss';
import { CONSTANTS } from '../../utils/Constants';
import Button from '@material-ui/core/Button';
import { Avatar } from '@material-ui/core';
import Footer from '../../components/common/elements/Footer';
import iconBack2 from '../../resources/svg/home/iconArrowBack.svg';
import iconPedidoNoEncontrado from '../../resources/svg/alertError/iconPedidoNoEncontrado.svg';
import HeaderDelivery from '../headerDelivery/HeaderDelivery';
import Loading from '../common/elements/Loading';

class SinPedido extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoading: false,
      attemptsPerDay: true,
      openModal: false,
      validateNumberDoc: false,
      disabledContinue: true,
      isSchedule: false,
    };
  }

  onAccept = () => {
    if (this.state.attemptsPerDay) {
      this.props.history.push({
        pathname: CONSTANTS.PATH.LOGIN,
      });
    }
  };

  // onCloseAlert = () => {
  //   this.setState({
  //     openAlert: false,
  //   });
  // };

  back = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <section id="SinPedido">
        <Loading id="loading-login" showLoading={this.state.showLoading} />
        <div className="content-header">
          <HeaderDelivery />
        </div>
        <div className="container-backButton">
          <div className="content-backButton">
            <Button
              disableRipple
              onClick={this.back}
              className="back-button"
              startIcon={<Avatar src={iconBack2} />}
            >
              Volver
            </Button>
          </div>
        </div>
        <div className="container">
          <div className="content-img">
            <img src={iconPedidoNoEncontrado} alt="iconPedidoNoEncontrado" />
          </div>
          <div className="content-text">
            <h1>No tienes pedidos pendientes</h1>
            <p>
              Aquí podrás hacer seguimiento de tus futuros pedidos o compras
            </p>
          </div>
          <Button
            disableRipple
            onClick={this.onAccept}
            className="enter-button"
          >
            Volver al Inicio
          </Button>
        </div>
        <div className="footer-token">
          <Footer />
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  // tu mapStateToProps...
});

const mapDispatchToProps = (dispatch) => ({
  // tu mapDispatchToProps...
});

// export default SinPedido
export default connect(mapStateToProps, mapDispatchToProps)(SinPedido);
