import React from 'react';
import iconBranding from '../../../resources/svg/footer/iconBranding.svg';
import iconEnvio from '../../../resources/svg/footer/iconEnvio.svg';
import iconSeguimiento from '../../../resources/svg/footer/iconSeguimiento.svg';
import iconSeguridad from '../../../resources/svg/footer/iconSeguridad.svg';
import './Footer.scss';
export default ({}) => (
  <div id="footer">
    <div className="container-footer">
      <div className="footer d-flex">
        <div className="icons-container">
          <div className="item-content">
            <img src={iconEnvio} alt="icon-envio" className="icon-envio" />
            <p>
              <b>Envío gratis</b>
              <br />a todo el Perú
            </p>
          </div>
          <div className="item-content">
            <img
              src={iconSeguimiento}
              alt="icon-Seguimiento"
              className="icon-seguimiento"
            />
            <p>
              <b>Seguimiento</b>
              <br />
              fácil y rápido
            </p>
          </div>
          <div className="item-content">
            <img
              src={iconSeguridad}
              alt="icon-Seguridad"
              className="icon-seguridad"
            />
            <p>
              <b>Entrega</b>
              <br />
              segura
            </p>
          </div>
        </div>
        <div className="text-container">
          <div className="line" />
          <div className="text-content">
            <p>© 2024 Movistar. Todos los derechos reservados.</p>
            <div className="links-container">
              <a href="#">Reclamos y solicitudes en línea</a>
              <div></div>
              <a href="#">Consultas de reclamos</a>
              <div className="separador"></div>
              <a href="#">Información abonados y usuarios</a>
              <div></div>
              <a href="#">Libro de Reclamaciones</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
